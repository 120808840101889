import React from "react";

const CurrencyFormat = ({
  value,
  thousandSpacing,
  displayType,
  thousandSeparator,
  prefix,
}) => {
  // Function to format the number
  const formatNumber = (num) => {
    if (isNaN(num) || num === null || num === undefined) {
      return "";
    }

    // Convert number to string and split into parts
    const parts = num.toString().split(".");
    let integerPart = parts[0];
    const decimalPart = parts.length > 1 ? "." + parts[1] : "";

    // Handle thousand separator
    if (thousandSeparator) {
      integerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandSpacing === "2s" ? "," : ""
      );
    }

    // Concatenate prefix and formatted number
    return `${prefix || ""}${integerPart}${decimalPart}`;
  };

  return (
    <span>
      {displayType === "text" ? (
        formatNumber(value)
      ) : (
        <input type="text" value={formatNumber(value)} readOnly />
      )}
    </span>
  );
};

export default CurrencyFormat;
